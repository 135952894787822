
import Lang from "@/resources/js/components/Lang";

export default {
    name: "UsersLoginForm",
    mixins: [Lang],

    data: function () {
        return {
            errors: [],
            form: {
                email: null,
                password: null,
            }
        }
    },
    methods: {
        login: function () {
            this.$http.post('/login', this.form)
                .then(response => {
                    localStorage.setItem('token', response.data.data.token);
                    // store.state.user.loggedIn = true;
                    this.$store.commit('login', response.data.data.token);
                    this.axios.defaults.headers.common['Authorization'] = "Bearer " + response.data.data.token;
                    this.$router.push('account').catch();
                })
                .catch(error => {
                    this.errors.push(error.response.data.error.description);
                });

        },

    },

}